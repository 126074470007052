import {onLoad} from "../base/onLoad";

onLoad(() => {
   const menuLinks = document.querySelectorAll('[data-open-menu]');
   const overLay = document.querySelector('[data-menu-overlay]');

   if(menuLinks.length <=0 || !overLay) {
       return;
   }

   menuLinks.forEach(menuLink => {
       menuLink.addEventListener('click', function() {
           overLay.classList.toggle('open');

           if(overLay.classList.contains('open')) {
               document.body.style.overflow = 'hidden';
           } else {
               document.body.style.overflow = 'unset';
           }
       }, false);
   });

   buildMenu();
});

function buildMenu() {
    const expandIcon = '<i class="far fa-chevron-down"></i>';
    const collapseIcon = '<i class="far fa-chevron-up"></i>';

    const menu = document.querySelector('.menu-overlay');

    const menuItemsWithSubMenus = menu.querySelectorAll('.menu-item-has-children');

    menuItemsWithSubMenus.forEach(menuItem => {
        const plus = document.createElement('button');
        plus.innerHTML = expandIcon;
        plus.classList.add('expand-menu');

        const link = menuItem.querySelector('a');

        const firstItem = menuItem.querySelector(':first-child');
        firstItem.appendChild(plus);

        const submenu = menuItem.querySelector('.sub-menu');

        link.addEventListener('click', function (event) {
            event.preventDefault();
           toggle();
        });

        function toggle() {
            submenu.classList.toggle('hidden');
            if(submenu.classList.contains('hidden')) {
                plus.innerHTML = expandIcon;
            } else {
                plus.innerHTML = collapseIcon;
            }
        }

        toggle();
        // const event = new Event('click');
        // plus.dispatchEvent(event);
    })
}