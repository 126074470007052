import {onLoad} from "../base/onLoad";

onLoad(() => {
   const collapseContainers = document.querySelectorAll('.collapse-container');
   collapseContainers.forEach(i => new Collapse(i));
});

class Collapse {
    constructor(container) {
        this.container = container;
        this.items = this.container.querySelectorAll('.collapse-item');

        if(this.items.length > 0) {
            this.init();
        }
    }

    setCollapsed(item, collapsed = true) {
        item.setAttribute('data-collapsed', collapsed);
    }

    init() {
        this.items.forEach(i => {
            this.setCollapsed(i, true);
            const toggle = i.querySelector('[data-toggle]');
            if(toggle) {
                toggle.addEventListener('click', () => {
                    this.toggle(i);
                });
            }
        });

        this.setCollapsed(this.items[0], false);
    }

    toggle(item) {
        const isCollapsed = item.getAttribute('data-collapsed') === 'true';

        this.items.forEach(i => this.setCollapsed(i, true));
        this.setCollapsed(item, !isCollapsed);
    }
}