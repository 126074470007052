import {onLoad} from "../base/onLoad";

onLoad(() => {
   const ginput_containers = document.querySelectorAll('.ginput_container_fileupload');
   ginput_containers.forEach(ginput_container => {
      new Gform_file_upload(ginput_container);
   });
});

class Gform_file_upload {
    constructor(container) {
        this.container = container;

        this.input = this.container.querySelector('input[type="file"]');
        this.textContainer = this.container.querySelector('.gform_fileupload_rules');
        if(this.input && this.textContainer) {
            this.ogHtml = this.textContainer.innerHTML;
            this.input.addEventListener('change', this.onChange.bind(this));
        }
    }

    onChange() {
        const files = this.input.files;
        if(files.length > 0) {
            const names = [];
            for(let i = 0; i < files.length; i++) {
                names.push(files[i].name);
            }
            this.textContainer.innerHTML = names.join(', ');
        } else {
            this.textContainer.innerHTML = this.ogHtml;
        }
    }
}