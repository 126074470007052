import {onLoad} from "../base/onLoad";

window.blobCount = 0;
window.blobSpeed = 20;

class Blob {
    /**
     *
     * @param element HTMLElement
     */
    constructor(element) {
        this.blobId = window.blobCount++;
        this.element = element;

        this.createKeyframe();
        this.fixAspectRatio();
    }

    randomInt(min, max) { // min and max included
        return Math.floor(Math.random() * (max - min + 1) + min)
    }

    createKeyframe() {
        const style = document.createElement('style');
        style.type = 'text/css';
        let keyFrames = `
            @-webkit-keyframes BLOB_NAME {
                  0%, 100% {
                    border-radius: R_LOW% R_MID% R_MID% R_LOW% / R_LOW% R_LOW% R_MID% R_MID%;
                  }
                  34% {
                    border-radius: R_HIGH% R_LOW% R_MID% R_MID% / R_LOW% R_LOW% R_HIGH% R_HIGH%;
                  }
                  67% {
                    border-radius: R_HIGH% R_MID% R_MID% R_HIGH% / R_HIGH% R_HIGH% R_MID% R_MID%;
                  }
            }
            
            @-moz-keyframes BLOB_NAME {
                  0%, 100% {
                    border-radius: R_LOW% R_MID% R_MID% R_LOW% / R_LOW% R_LOW% R_MID% R_MID%;
                  }
                  34% {
                    border-radius: R_HIGH% R_LOW% R_MID% R_MID% / R_LOW% R_LOW% R_HIGH% R_HIGH%;
                  }
                  67% {
                    border-radius: R_HIGH% R_MID% R_MID% R_HIGH% / R_HIGH% R_HIGH% R_MID% R_MID%;
                  }
            }
        `;

        keyFrames = keyFrames.replaceAllOccurrences("R_LOW", () => this.randomInt(30,50).toString());
        keyFrames = keyFrames.replaceAllOccurrences("R_MID", () => this.randomInt(50,70).toString());
        keyFrames = keyFrames.replaceAllOccurrences("R_HIGH", () => this.randomInt(70,100).toString());

        keyFrames = keyFrames.replaceAllOccurrences("R_1", () => this.randomInt(60,100).toString());
        keyFrames = keyFrames.replaceAllOccurrences("BLOB_NAME", "blob-" + this.blobId);

        style.innerHTML = keyFrames;
        document.getElementsByTagName('head')[0].appendChild(style);

        this.element.style.animation = `blob-${this.blobId} ${window.blobSpeed}s linear infinite`;
    }

    // If aspect ratio is not supported in the current browser fix it up by making it square
    fixAspectRatio() {
        if(!this.element.classList.contains('aspect-ratio-1/1')) {
            return;
        }

        if(CSS.supports('aspect-ratio', '1/1')) {
            return;
        }

        const width = this.element.offsetWidth
        const height = this.element.offsetHeight;

        if(width > height) {
            this.element.style.height = width + 'px';
        } else {
            this.element.style.width = height + 'px';
        }
    }
}

onLoad(() => {
    const blobs = document.getElementsByClassName('blob');
    for(const blob of blobs) {
        new Blob(blob);
    }
});