import {onLoad} from "../base/onLoad";

onLoad(() => {
   const has_scrolled_elements = document.querySelectorAll('[data-has-scrolled]');

    window.addEventListener('scroll', () => {
        const scroll_position = window.scrollY;
        has_scrolled_elements.forEach(element => {
            element.setAttribute('data-has-scrolled', scroll_position > 0 ? 'true' : 'false');
        });
    });
});