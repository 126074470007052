import {onLoad} from "../base/onLoad";
import {Splide} from "@splidejs/splide";

onLoad(() => {
    const imageSlider = document.querySelector('.front_page_header .image-slider');
    const imageContentSlider = document.querySelector('.front_page_header .image-slider-content');
    const extraImageSlider = document.querySelector('.front_page_header .extra-image-slider');

    if(!imageSlider || !imageContentSlider || !extraImageSlider) {
        return;
    }

    const imageContentSliderInstance = new Splide(imageContentSlider, {
       perPage: 1,
        perMove: 1,
        type: 'loop',
        loop: true,
        arrows: false,
        autoplay: true,
        autoplayInterval: 5000,
        pagination: true,
    });

    imageContentSliderInstance.mount();

    const inactiveOptions = {
        type: 'loop',
        perPage: 1,
        gap: 0,
        drag: false,
        paginationKeyboard: false,
        pagination: false,
        autoplay: false,
        arrows: false,
    };


    const splideInstance = new Splide(imageSlider, inactiveOptions);
    splideInstance.mount();

    const extraSplideInstance = new Splide(extraImageSlider, inactiveOptions);
    extraSplideInstance.mount();

    imageContentSliderInstance.on('move', () => {
        setTimeout(() => {
            splideInstance.go(imageContentSliderInstance.index);
        }, 100);

        setTimeout(() => {
            extraSplideInstance.go(imageContentSliderInstance.index);
        }, 200);
    });
});